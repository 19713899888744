import * as React from 'react';
import styles from './styles.module.scss';
import Header, { HEADER_TYPE } from '@components/Header';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';
import LayoutSection from '@components/LayoutSection';
import LayoutPage from '@components/LayoutPage';
import { useNavigate } from 'react-router-dom';
import { useLogInUser } from '@hooks/useLogInUser';
import SignInForm from '@components/SignInForm';
import { UserContext } from '@contexts/UserContext';
import useCaptchaRequired from '@hooks/useCaptchaNeeded';
import SidebarWrapper from '@components/SidebarWrapper';
import MiniCart from '@components/MiniCart';
import BlockFormWrapper from '@components/BlockFormWrapper';
import InitSignUp from '@components/InitSignUp';
import useCartProducts from '@hooks/useCartProducts';
import { CheckoutPrice } from '@utils/types/store';

export default function SignInPayment() {
  const { t } = useTranslation(['shop', 'routes', 'common', 'forms']);
  const navigate = useNavigate();
  const [setError] = React.useState<any>('');
  const [isCaptchaRequired, setIsCaptchaRequired] =
    React.useState<boolean>(false);
  const location = useLocation();
  const {
    data: { products, total },
    isLoadingCheckoutFromApi,
    isLoadingGettingProduct,
  } = useCartProducts();
  const redirectRoute = location?.state?.redirectRoute;

  const { setIsLoggedIn, isLoggedIn } = React.useContext(UserContext);

  React.useEffect(() => {
    if (isLoggedIn) {
      navigate(t('routes:home'));
    }
  }, [navigate, isLoggedIn, t]);

  const onSuccess = () => {
    navigate(redirectRoute ?? t('routes:cart'));
    setIsLoggedIn(true);
  };

  const onError = () => {
    setError('Error');
  };

  const onSuccessCaptcha = () => {
    if (captchaInfo && captchaInfo?.recaptcha) {
      setIsCaptchaRequired(captchaInfo?.recaptcha);
    }
  };

  const {
    mutate: signIn,
    isLoading,
    isError,
    error
  } = useLogInUser(onSuccess, onError);

  const { data: captchaInfo } = useCaptchaRequired(true, onSuccessCaptcha);

  const onSubmitForm = React.useCallback(
    ({
      email,
      password,
      recaptcha,
    }: {
      email: string;
      password: string;
      recaptcha: string;
    }) => {
      signIn({ email, password, recaptcha });
    },
    [signIn]
  );

  return (
    <>
      <Header type={HEADER_TYPE.DEFAULT} />
      <LayoutPage>
        <LayoutSection noMarginBottom={true} hasSide isCentered>
          <div className={styles.formWrapper}>
            <div className={styles.container}>
              <h1 style={{ marginBottom: '44px', fontSize: '2.4rem' }}>
                {t('common:shops.proceedPayment')}
              </h1>

              <BlockFormWrapper
                hasMaxWidth
                title={t('common:shops.alreadyClient')}
              >
                <SignInForm
                  isCaptachRequired={isCaptchaRequired}
                  onSubmit={onSubmitForm}
                  isLoading={isLoading}
                  isError={isError}
                  error={error}
                  isInSigninAndPay={true}
                />
              </BlockFormWrapper>
            </div>

            <BlockFormWrapper hasMaxWidth title={t('common:shops.newClient')}>
              <InitSignUp />
            </BlockFormWrapper>
          </div>
          <SidebarWrapper>
            <MiniCart
              products={products}
              isLoadingCheckoutFromApi={isLoadingCheckoutFromApi}
              total={total as CheckoutPrice}
              isLoadingGettingProduct={isLoadingGettingProduct}
            />
          </SidebarWrapper>
        </LayoutSection>
      </LayoutPage>
      <Outlet />
    </>
  );
}
