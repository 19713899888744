import styles from './styles.module.scss';
import { PAYMENT_METHODS } from '@utils/types/store';
import * as React from 'react';
import ICON_AMEX from '@assets/images/amex.svg';
import ICON_MAESTRO from '@assets/images/maestro.svg';
import ICON_VISA from '@assets/images/visa.svg';
import ICON_MASTERCARD from '@assets/images/mastercard.svg';
import ICON_PAYPAL from '@assets/images/paypal.svg';
import { useTranslation } from 'react-i18next';
type Props = {
  onSelect: (paymentType: PAYMENT_METHODS) => void;
  selection: PAYMENT_METHODS;
};
export default function PaymentSelection({ onSelect, selection }: Props) {
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSelect(e?.target.value as PAYMENT_METHODS);
    },
    [onSelect]
  );

  const { t } = useTranslation(['routes', 'common', 'shop']);

  return (
    <fieldset className={styles.container}>
      <label htmlFor="credit_card" className={styles.radioContainer}>
        <input
          onChange={onChange}
          type="radio"
          id="credit_card"
          name="credit_card"
          value={PAYMENT_METHODS.CREDIT_CARD}
          checked={selection === PAYMENT_METHODS.CREDIT_CARD}
        />
        <span className={styles.checkmark}></span>

        <span className={styles.icons}>
          <p>{t('common:shops.creditCard')}</p>
          <img src={ICON_VISA} alt="visa" />
          <img src={ICON_MASTERCARD} alt="mastercard" />
          <img src={ICON_MAESTRO} alt="maestro" />
          <img src={ICON_AMEX} alt="amex" />
        </span>
      </label>

      <label htmlFor="transfer" className={styles.radioContainer}>
        <input
          onChange={onChange}
          type="radio"
          id="transfer"
          name="transfer"
          value={PAYMENT_METHODS.TRANSFER}
          checked={selection === PAYMENT_METHODS.TRANSFER}
        />
        <span className={styles.checkmark}></span>
        <span className={styles.icons}>
          <p>{t('common:shops.transfer')}</p>
        </span>
      </label>
      <label htmlFor="paypal" className={styles.radioContainer}>
        <input
          onChange={onChange}
          type="radio"
          id="paypal"
          name="paypal"
          value={PAYMENT_METHODS.PAYPAL}
          checked={selection === PAYMENT_METHODS.PAYPAL}
        />
        <span className={styles.checkmark}></span>

        <span className={styles.icons}>
          <img src={ICON_PAYPAL} alt="paypal" />
          <img src={ICON_VISA} alt="visa" />
          <img src={ICON_MASTERCARD} alt="mastercard" />
          <img src={ICON_MAESTRO} alt="maestro" />
          <img src={ICON_AMEX} alt="amex" />
        </span>
      </label>
    </fieldset>
  );
}
