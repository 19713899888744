/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AddPurchaseResponse,
  CartProductItem,
  CartProductItemForCheckout,
  CheckoutBIllingInformation,
  CheckoutDiscountAndTax,
  CheckoutPrice,
  CheckoutRawProduct,
  CheckPayWithPaypal,
  PaymentRecap,
  PaymentResult,
  PAYMENT_RESULT,
  ProductSupremo,
  ProductUranium,
  ProductVersion,
  PRODUCT_TYPE,
  PRODUCT_VERSION_ID,
  ProfessionalProfile,
  RenewUpgradeProductSupremo,
  RenewUpgradeProductUranium,
} from '@utils/types/store';

import { ResponseError } from '@utils/types/generic';
import {
  first,
  flatten,
  identity,
  isEmpty,
  isEqual,
  last,
  omit,
  omitBy,
  pickBy,
  uniqWith,
} from 'lodash';
import { Base64 } from 'js-base64';
import { ILicense } from '@utils/types/licenses';
import { formatDate } from '@utils/types/helpers';
import { t } from 'i18next';
import {
  ILicenseThumbnail,
  IPurchase,
  IPurchaseDocument,
} from '@utils/types/purchase';
import { UserDataResponse } from '@utils/types/user';

export enum SUBSCRIPTION_DURATION {
  THREE_MONTH = 3,
  ONE_YEAR = 12,
  TWO_YEAR = 24,
  THREE_YEAR = 36,
  LIFETIME = 0,
}

export enum ERRORS_TYPE {
  INVALID_LICENSE_ID = 'INVALID_LICENSE_ID',
  UNAUTHORIZED = 'UNAUTHORIZED',
  PAYMENT_INCOMPLETE = 'PAYMENT_INCOMPLETE',
  INVALID_PAYMENT = 'INVALID_PAYMENT',
  INVALID_PRODUCT = 'INVALID_PRODUCT',
  ALREADY_ACTIVE = 'ALREADY_ACTIVE',
  NOT_LOGGED = 'NOT_LOGGED',
  INVALID_QUERY = 'INVALID_QUERY',
  INVALID_REQUEST = 'INVALID_REQUEST',
  INVALID_CART = 'INVALID_CART',
  USER_BANNED = 'USER_BANNED',
  TOO_EARLY = 'TOO_EARLY',
  LICENSE_NOT_UPGRADABLE = 'LICENSE_NOT_UPGRADABLE',
  ALREADY_UPDATED = 'ALREADY_UPDATED',
  INVALID_UPGRADE = 'INVALID_UPGRADE',
  INVALID_CODE = 'INVALID_CODE',
  INVALID_CREDENTIALS = 'INVALID_CREDENTIALS'
}

export function normalizeProductsToCart(cart: CartProductItem) {
  return {
    productType: cart?.productType,
    quantity: cart?.quantity,
    connections: cart?.connections,
    teamAssistance: cart?.teamAssistanceProfileId,
    upgradeId: cart?.upgradeId,
  };
}

function getSubscriptionDurationType(
  months: number
): SUBSCRIPTION_DURATION | undefined {
  switch (months) {
    case 0:
      return SUBSCRIPTION_DURATION.LIFETIME;
    case 3:
      return SUBSCRIPTION_DURATION.THREE_MONTH;
    case 12:
      return SUBSCRIPTION_DURATION.ONE_YEAR;
    case 24:
      return SUBSCRIPTION_DURATION.TWO_YEAR;
    case 36:
      return SUBSCRIPTION_DURATION.THREE_YEAR;
    default:
      return undefined;
  }
}

export function normalizeProductVersion(productVersion: any): ProductVersion {
  const subProducts: Partial<ProductVersion[]> =
    productVersion?.childs
      ?.map(normalizeProductVersion)
      .map((subProd: ProductVersion) => omit(subProd, ['subProducts'])) || [];

  const firstChild = first(subProducts);
  return {
    id: productVersion?.id,
    price: productVersion?.price ?? 0,
    quantity: productVersion?.quantity ?? 0,
    upgradeId: productVersion?.toUpgradeID ?? null,
    autoRenewable: Boolean(productVersion.renewEnabled),
    name: productVersion?.name ?? '',
    minAmount: firstChild?.minAmount ?? productVersion?.MinAmount ?? 0,
    maxAmount: firstChild?.maxAmount ?? productVersion?.MaxAmount ?? 0,
    description: productVersion?.itaDescription ?? '',
    shortDescription: productVersion?.Description ?? '',
    image: productVersion?.image ?? '',
    isCumulable: Boolean(productVersion.cumulable),
    subscriptionDuration:
      getSubscriptionDurationType(productVersion?.months) ?? 0,
    isRenewable: Boolean(productVersion.renewable),
    promotionalCode: productVersion?.promotionalCode ?? '',
    promotionalDiscountAmount:
      productVersion?.PromotionalCodeDiscountAmount ?? 0,
    usilioIntegration: Boolean(productVersion.usilioIntegration),
    shouldShowMultiConnection: Boolean(productVersion?.childs?.length ?? 0),
    durationMonths: productVersion.months ?? undefined,
    defaultProfile: first(productVersion?.profiles),
    profiles: productVersion?.profiles,
    newExpire: productVersion?.newExpire,
    promoDesc: productVersion?.promoDesc,
    subProducts,
  };
}

export function normalizeSupremoProduct(product: any): ProductSupremo {
  const profiles = product?.profiles?.map(normalizeProfessionalProfile);
  return {
    id: product?.id,
    name: product?.name,
    nameId: PRODUCT_TYPE.SUPREMO,
    description: product?.itaDescription,
    products: {
      yearly:
        [
          ...product?.products
            ?.filter((el: any) => el?.months === SUBSCRIPTION_DURATION.ONE_YEAR)
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) => pr.months === SUBSCRIPTION_DURATION.ONE_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(normalizeProductVersion),
        ] ?? [],
      quarterly:
        [
          ...product?.products
            .filter(
              (el: any) => el?.months === SUBSCRIPTION_DURATION.THREE_MONTH
            )
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) =>
                      pr.months === SUBSCRIPTION_DURATION.THREE_MONTH
                  ),
                }
                : p
            )
            .sort()
            .map(normalizeProductVersion),
        ] ?? [],
      biennial:
        [
          ...product?.products
            .filter((el: any) => el?.months === SUBSCRIPTION_DURATION.TWO_YEAR)
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) => pr.months === SUBSCRIPTION_DURATION.TWO_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(normalizeProductVersion),
        ] ?? [],
      triennial:
        [
          ...product?.products
            .filter(
              (el: any) => el?.months === SUBSCRIPTION_DURATION.THREE_YEAR
            )
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) =>
                      pr.months === SUBSCRIPTION_DURATION.THREE_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(normalizeProductVersion),
        ] ?? [],
    },
  };
}

// UPGRADE ---------------------

export function normalizeUpgradeRenewSupremoProduct(
  product: any
): RenewUpgradeProductSupremo {
  const profiles = product.profiles?.map(normalizeProfessionalProfile);

  return {
    titlePage: product?.name,
    type: product?.type,
    software:
      product?.software === 'SUPREMO'
        ? PRODUCT_TYPE.SUPREMO
        : product?.software === 'URANIUM'
          ? PRODUCT_TYPE.URANIUM
          : PRODUCT_TYPE.CONSOLE,
    startingControls: product?.startingControls,
    startingProfile: product?.startingProfile,
    products: {
      yearly:
        [
          ...product?.products
            ?.filter((el: any) => el?.months === SUBSCRIPTION_DURATION.ONE_YEAR)
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) => pr.months === SUBSCRIPTION_DURATION.ONE_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(
              normalizeProductVersionForUpgrade(
                product?.startingProfile,
                product?.startingControls,
                product?.type
              )
            ),
        ] ?? [],
      quarterly:
        [
          ...product?.products
            .filter(
              (el: any) => el?.months === SUBSCRIPTION_DURATION.THREE_MONTH
            )
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) =>
                      pr.months === SUBSCRIPTION_DURATION.THREE_MONTH
                  ),
                }
                : p
            )
            .sort()
            .map(
              normalizeProductVersionForUpgrade(
                product?.startingProfile,
                product?.startingControls,
                product?.type
              )
            ),
        ] ?? [],
      biennial:
        [
          ...product?.products
            .filter((el: any) => el?.months === SUBSCRIPTION_DURATION.TWO_YEAR)
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) => pr.months === SUBSCRIPTION_DURATION.TWO_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(
              normalizeProductVersionForUpgrade(
                product?.startingProfile,
                product?.startingControls,
                product?.type
              )
            ),
        ] ?? [],
      triennial:
        [
          ...product?.products
            .filter(
              (el: any) => el?.months === SUBSCRIPTION_DURATION.THREE_YEAR
            )
            .map((p: any) =>
              p.usilioIntegration
                ? {
                  ...p,
                  profiles: profiles.filter(
                    (pr: any) =>
                      pr.months === SUBSCRIPTION_DURATION.THREE_YEAR
                  ),
                }
                : p
            )
            .sort()
            .map(
              normalizeProductVersionForUpgrade(
                product?.startingProfile,
                product?.startingControls,
                product?.type
              )
            ),
        ] ?? [],
    },
  };
}

export function normalizeUpgradeRenewUraniumProduct(
  product: any
): RenewUpgradeProductUranium {
  return {
    titlePage: product?.name,
    type: product?.type,
    software:
      product?.software === 'SUPREMO'
        ? PRODUCT_TYPE.SUPREMO
        : product?.software === 'URANIUM'
          ? PRODUCT_TYPE.URANIUM
          : PRODUCT_TYPE.CONSOLE,
    startingControls: product?.startingControls,
    startingProfile: product?.startingProfile,
    products: product?.products.map(normalizeProductVersion).sort(),
  };
}

export function normalizeUpgradeRenewConsoleProduct(
  product: any
): RenewUpgradeProductUranium {
  return {
    titlePage: product?.name,
    type: product?.type,
    software:
      product?.software === 'SUPREMO'
        ? PRODUCT_TYPE.SUPREMO
        : product?.software === 'URANIUM'
          ? PRODUCT_TYPE.URANIUM
          : PRODUCT_TYPE.CONSOLE,
    startingControls: product?.startingControls,
    startingProfile: product?.startingProfile,
    products: product?.products.map(normalizeProductVersion).sort(),
  };
}

export const normalizeProductVersionForUpgrade =
  (startingProfile: any, startingControl: any, productType: any) =>
    (productVersion: any): ProductVersion => {
      const subProducts: Partial<ProductVersion[]> =
        productVersion?.childs
          ?.map(normalizeProductVersion)
          .map((subProd: ProductVersion) => omit(subProd, ['subProducts'])) || [];

      const firstChild = first(subProducts);

      let defaultProfile = startingProfile ? productVersion?.profiles?.find((p: any) => p.id === startingProfile) : null;
      defaultProfile = defaultProfile || first(productVersion?.profiles);

      return {
        id: productVersion?.id,
        price: productVersion?.price ?? 0,
        quantity: productVersion?.quantity ?? 0,
        upgradeId: productVersion?.toUpgradeID ?? null,
        autoRenewable: Boolean(productVersion.renewEnabled),
        name: productVersion?.name ?? '',
        minAmount: firstChild?.minAmount ?? productVersion?.MaxAmount ?? 0,
        maxAmount: firstChild?.maxAmount ?? productVersion?.MaxAmount ?? 0,
        startingControl: startingControl,
        description: productVersion?.itaDescription ?? '',
        shortDescription: productVersion?.Description ?? '',
        image: productVersion?.image ?? '',
        isCumulable: Boolean(productVersion.cumulable),
        subscriptionDuration:
          getSubscriptionDurationType(productVersion?.months) ?? 0,
        isRenewable: Boolean(productVersion.renewable),
        promotionalCode: productVersion?.promotionalCode ?? '',
        promotionalDiscountAmount:
          productVersion?.PromotionalCodeDiscountAmount ?? 0,
        usilioIntegration: Boolean(productVersion.usilioIntegration),
        shouldShowMultiConnection: Boolean(productVersion?.childs?.length ?? 0),
        durationMonths: productVersion.months ?? undefined,
        defaultProfile: defaultProfile,
        sortIndex: productVersion?.sortIndex,
        site: productVersion?.site,
        profiles: productVersion?.profiles,
        newExpire: productVersion?.newExpire,
        promoDesc: productVersion?.promoDesc,
        isDowngrade: productVersion?.isDowngrade === true,
        subProducts
      };
    };

// -----------------------------------

export function normalizeUserData(user: any): any {
  return {
    email: user?.Email ?? undefined,
    username: user?.Username ?? undefined,
    registrationComplete: user?.RegistrationComplete,
    recaptcha: user?.Recaptcha ?? undefined,
    isReseller: user?.Reseller,
    hasUnreadArticle: user?.HasUnreadArticles,
  };
}

export function normalizeCountryData(countryList: any): any {
  return countryList.reduce((acc: any, el: any) => {
    return [
      ...acc,
      {
        label: el?.Description,
        code: el?.Id,
        isInEurope: el?.Europe,
      },
    ];
  }, []);
}

export function normalizeRegisterData(data: any) {
  const registerPhase1 = pickBy(
    {
      Email: data?.email,
      Password: Base64.encode(data?.password),
      Username: data?.username,
    },
    identity
  );

  const registerPhase2 = pickBy(
    {
      TipoUtente: data?.userType ?? '',
      NomeCognome: data?.firstnameAndLastname ?? '',
      Nazione: data?.country ?? '',
      Vat: data?.vatNumber ?? '',
      Provincia: data?.province ?? '',
      StatoEstero: data?.provinceState ?? '',
      CodicePA: data?.paCode ?? '',
      CodiceFiscale: data?.fiscalCode ?? '',
      B2BPEC: data?.codeOrPec ?? '',
    },
    identity
  );
  const registerPhase3 = pickBy(
    {
      Cap: (data?.zipCode || data?.zipCodeITA) ?? '',
      Citta: data?.city ?? '',
      Civico: data?.streetAndNumber ?? '',
      Newsletter: data?.newsletter ?? '',
      Telefono: data?.tel ?? '',
      CodiceConcessionario: '',
      AdminEmail: data?.adminEmail ?? '',
      Recaptcha: data?.recaptcha ?? '',
    },
    identity
  );
  return omitBy({ registerPhase1, registerPhase2, registerPhase3 }, isEmpty);
}

export function normalizeProvinceData(provinceList: any): any {
  return provinceList.reduce((acc: any, el: any) => {
    return [
      ...acc,
      {
        label: el.Description,
        code: el.Id,
      },
    ];
  }, []);
}

export function normalizeUraniumProduct(product: any): ProductUranium {
  return {
    id: product?.id,
    name: product?.name,
    nameId: PRODUCT_TYPE.URANIUM,
    description: product?.itaDescription,
    products: product?.products.map(normalizeProductVersion).sort(),
  };
}

export function normalizeProfessionalProfile(
  profile: any
): ProfessionalProfile {
  return {
    id: profile?.Id,
    name: profile?.Name?.split(':')[0] ?? '',
    description: profile?.Name ?? '',
    price: profile?.Price,
    months: profile?.Months,
  };
}

export function normalizeCheckoutToRawProducts(autoRenew: boolean) {
  return function (item: CartProductItemForCheckout): CheckoutRawProduct {
    return {
      renewEnabled: Boolean(item?.renewEnabled) && autoRenew,
      toUpgradeId: item?.toUpgradeId ?? 0,
      id: item?.id ?? 0,
      profile: item?.profile,
      quantity: item?.quantity ?? 0,
      childs:
        item?.children?.map(normalizeCheckoutToRawProducts(autoRenew)) ?? [],
    };
  };
}

export function normalizeCheckoutProductRandomName(
  acc: { [key: string]: CheckoutRawProduct },
  product: CheckoutRawProduct
) {
  return {
    ...acc,
    [`p-${product.id}-${Math.floor(Date.now() * Math.random())}`]: {
      ...product,
    },
  };
}

function normalizeProductQuantityForCheckout(
  item: CartProductItem
): CartProductItem[] {
  let newItems: CartProductItem[] = [];
  for (let index = 0; index < item.quantity; index++) {
    newItems = [...newItems, { ...item, quantity: 1 }];
  }

  return newItems;
}

export function normalizeCartItemsForCheckout(products: ProductVersion[]) {
  return function (acc: CartProductItemForCheckout[], item: CartProductItem) {
    let currentProductByItemId =
      item?.id === PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS ||
        item?.id === PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR ||
        item?.id === PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS ||
        item?.id === PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL ||
        //..........
        item?.id === PRODUCT_VERSION_ID.UPGRADE_PRO_TO_GOLD ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_TAPE ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_GOLD ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_DB ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_SHADOW ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_BASE_TO_PRO_VIRTUAL ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_VIRTUAL_TO_GOLD ||
        item?.id === PRODUCT_VERSION_ID.UPGRADE_PRO_SHADOW_TO_PRO_VIRTUAL
        ? products.find((p) => p.localId === item.localId)
        : products.find((p) => p.id === item.id);

    if (
      item?.fromLicense &&
      item?.renewOrUpgrade === 'RENEW' &&
      currentProductByItemId
    ) {
      currentProductByItemId = {
        ...currentProductByItemId,
        upgradeId: item?.fromLicense,
      };
    }

    return [
      ...acc,
      {
        id: currentProductByItemId?.id,
        renewEnabled: Boolean(currentProductByItemId?.isRenewable),
        profile:
          currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO ||
            currentProductByItemId?.id ===
            PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL
            ? (currentProductByItemId?.profile as number)
            : (item?.teamAssistanceProfileId as number),
        // item?.teamAssistanceProfileId ??
        // currentProductByItemId?.defaultProfile?.id ??
        // 0,
        toUpgradeId: currentProductByItemId?.upgradeId ?? 0,
        quantity: item?.quantity,
        children: currentProductByItemId?.subProducts.map((prod) => {
          return {
            id: prod?.id,
            renewEnabled: Boolean(prod?.isRenewable),
            profile:
              currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL
                ? (prod?.profile as number)
                : (item?.teamAssistanceProfileId as number),

            toUpgradeId: prod?.upgradeId ?? 0,
            quantity:
              currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_MONTHS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_ONE_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_THREE_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.SUPREMO_EXTRA_CONTROL_TWO_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SOLO_TO_BUSINESS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_THREE_MONTH_TO_ONE_YEAR ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_ONE_YEAR_TO_TWO_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_TWO_YEAR_TO_THREE_YEARS ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_CONTROL_AGGIUNTIVO ||
                currentProductByItemId?.id ===
                PRODUCT_VERSION_ID.UPGRADE_SUPREMO_PROFESSIONAL
                ? prod?.quantity
                : item?.connections,
          };
        }),
      },
    ];
  };
}

export function normalizeCumulableCartItemsForCheckout(
  items: CartProductItem[],
  products: ProductVersion[]
) {
  return items?.reduce(normalizeCartItemsForCheckout(products), []);
}

export function normalizeNotCumulableCartItemsForCheckout(
  items: CartProductItem[],
  products: ProductVersion[]
) {
  return flatten(items?.map(normalizeProductQuantityForCheckout)).reduce(
    normalizeCartItemsForCheckout(products),
    []
  );
}

function normalizeUserInfoFromBilling(data: any): CheckoutBIllingInformation {
  return {
    fullname: data?.TextNomeCognome ?? '',
    address: data?.TextIndirizzo ?? '',
    country: data?.TextPaese ?? '',
    city: data?.TextCitta ?? '',
    fiscalCode: data?.TextCodiceRow ?? null,
    taxNumber: data?.TextIvaRow ?? null,
  };
}
function normalizeCheckoutDiscountAndTax(item: any): CheckoutDiscountAndTax {
  const total = (first(item?.Price.split(' ')) as string) ?? '';
  return {
    percent: item?.Cat ?? '',
    total,
    description: item?.Desc,
  };
}

export default function normalizeCheckout(data: any): any {
  const currency = (last(data?.TextPrezzoFinale?.split(' ')) as string) ?? '€';
  const totalCount =
    (first(data?.TextPrezzoTotale2?.split(' ')) as string) ?? '0';
  const totalWithoutTax =
    (first(data?.TextSinglePriceNoIva?.split(' ')) as string) ?? '0';
  const total = (first(data?.TextPrezzoFinale?.split(' ')) as string) ?? '0';

  return {
    billingInformation: normalizeUserInfoFromBilling(data?.UserInfo),
    currency,
    totalCount,
    productPrices: uniqWith(
      data?.Products.map((p: any) => ({
        id: Number(p.Id),
        price: Number(p.Price) ?? 0,
      })),
      isEqual
    ),
    totalWithoutTax,
    total,
    tax: data.Discounts?.filter(
      (d: any) => d?.Type?.toLowerCase() === 'vat'
    ).map(normalizeCheckoutDiscountAndTax),
    discounts: data.Discounts?.filter(
      (d: any) => d?.Type?.toLowerCase() !== 'vat'
    ).map(normalizeCheckoutDiscountAndTax),
    B2BPecNeeded: data?.B2BPecNeeded,
  };
}

export function normalizeAddPurchase(data: any): AddPurchaseResponse {
  return {
    redirectUrl: data?.RedirectUrl ?? null,
  };
}

export function normalizeConfirmPayment(data: any): AddPurchaseResponse {
  return {
    redirectUrl: data ?? null,
  };
}

function errorTypeToMessageConverter(errorType: string): string {
  switch (errorType) {
    case ERRORS_TYPE.ALREADY_ACTIVE:
      return t('license:errors.alreadyActive');
    case ERRORS_TYPE.ALREADY_UPDATED:
      return t('license:errors.alreadyUpdated');
    case ERRORS_TYPE.INVALID_CART:
      return t('license:errors.invalidCart');
    case ERRORS_TYPE.INVALID_CODE:
      return t('license:errors.invalidCode');
    case ERRORS_TYPE.INVALID_LICENSE_ID:
      return t('license:errors.invalidLicenseId');
    case ERRORS_TYPE.INVALID_PAYMENT:
      return t('license:errors.invalidPayment');
    case ERRORS_TYPE.INVALID_PRODUCT:
      return t('license:errors.invalidProduct');
    case ERRORS_TYPE.INVALID_QUERY:
      return t('license:errors.invalidQuery');
    case ERRORS_TYPE.INVALID_REQUEST:
      return t('license:errors.invalidRequest');
    case ERRORS_TYPE.INVALID_UPGRADE:
      return t('license:errors.invalidUpgrade');
    case ERRORS_TYPE.LICENSE_NOT_UPGRADABLE:
      return t('license:errors.licenseNotUpgradable');
    case ERRORS_TYPE.NOT_LOGGED:
      return t('license:errors.notLogged');
    case ERRORS_TYPE.PAYMENT_INCOMPLETE:
      return t('license:errors.paymentIncomplete');
    case ERRORS_TYPE.TOO_EARLY:
      return t('license:errors.tooEarly');
    case ERRORS_TYPE.UNAUTHORIZED:
      return t('license:errors.unauthorized');
    case ERRORS_TYPE.USER_BANNED:
      return t('license:errors.userBanned');
    case ERRORS_TYPE.INVALID_CREDENTIALS:
      return t('forms:errorsState.invalidCredentials');

    default:
      return '';
  }
}

export function normalizeError(error: any): ResponseError {
  let message = errorTypeToMessageConverter(error?.response?.data?.type);
  if (message.length === 0 && error?.response?.data?.Message) {
    message = error?.response?.data?.Message;
  }

  return {
    type: error?.response?.data?.type,
    description: error?.response?.data?.description ?? t('common:errors.generic'),
    message,
  };
}

export function normalizePaymentRecapProduct(data: any) {
  const newOne = data.reduce((acc: any, el: any) => {
    return [
      ...acc,
      {
        id: el?.Id ?? '',
        name: el?.Name ?? '',
        quantity: Number(el?.Quant.split(' ')[0]),
        price: Number(el?.Price.replace(',', '.')) ?? 0,
      },
    ];
  }, []);

  return removeDuplicateAndIncrementQuantity(newOne);
}

const removeDuplicateAndIncrementQuantity = (products: ProductVersion[]) => {
  let uniqueData: ProductVersion[] = [];
  products.forEach((data) => {
    const index = uniqueData.findIndex(
      (item) =>
        item.id === data.id &&
        item.price === data.price &&
        /*item.quantity === data.quantity &&*/
        item.name === data.name
    );
    if (index === -1) {
      uniqueData = [...uniqueData, data];
    } else {
      uniqueData[index].quantity += data.quantity;
    }
  });
  return uniqueData;
};

export function normalizePaymentRecap(data: any): PaymentRecap {
  return {
    products: normalizePaymentRecapProduct(data?.Products),
    id: String(data?.PaymentId ?? ''),
    total: String(data?.Price ?? 0),
    supportMail: data?.SupportMail ?? '',
    currency: data?.Currency ?? '€',
    bankTransferInfo: {
      accountHolder: String(data?.AccountHolder ?? ''),
      bank: String(data?.Bank ?? ''),
      iban: String(data?.IBAN ?? ''),
      bicSwift: String(data?.BIC_SWIFT ?? ''),
      abi: String(data?.ABI ?? ''),
      cab: String(data?.CAB ?? ''),
      cc: String(data?.CC ?? ''),
      cin: String(data?.CIN ?? ''),
    },
    userInfo: normalizeUserInfoFromBilling(data?.UserInfo),
  };
}

export function normalizePaymentResult(data: any): PaymentResult {
  let result = PAYMENT_RESULT.PENDING;
  if (data === '1') {
    result = PAYMENT_RESULT.SUCCESS;
  } else if (data === '-1') {
    result = PAYMENT_RESULT.DECLINED;
  } else {
    result = PAYMENT_RESULT.PENDING;
  }
  return {
    result,
  };
}
const errorMap: any = {
  Email: 'email',
  Password: 'password',
  Username: 'username',
  TipoUtente: 'userType',
  NomeCognome: 'firstnameAndLastname',
  Nazione: 'country',
  Provincia: 'province',
  StatoEstero: 'provinceState',
  CodicePA: 'paCode',
  CodiceFiscale: 'fiscalCode',
  B2BPEC: 'codeOrPec',
  Cap: 'zipCode' || 'zipCodeITA',
  Vat: 'vatNumber',
  Citta: 'city',
  Civico: 'streetAndNumber',
  Newsletter: 'newsletter',
  Telefono: 'tel',
  CodiceConcessionario: '',
  AdminEmail: 'adminEmail',
  Recaptcha: 'recaptcha',
};

export function normalizeFormSignupError(data: any): string[] {
  const rawErrors = data?.response?.data?.ErrorsField ?? [];

  return rawErrors?.map((err: string) => errorMap[err]);
}

export function normalizeRecoveryPasswordError(error: any): ResponseError {
  let message = '';
  if (error?.response?.data) {
    if (!error?.response?.data.GrammaticallyCorrect) {
      message = t('forms:errorsState.invalid.email');
    } else if (!error?.response?.data.MailExists) {
      message = t('forms:errorsState.errorsFromApi.emailNotExists');
    }
  }
  if (message.length === 0) {
    message = t('forms:errorsState.general.submit');
  }

  return {
    type: error?.response?.data?.type,
    description: error?.response?.data?.description ?? t('common:errors.generic'),
    message
  };
}

export function normalizeCheckPayWithPaypal(data: any): CheckPayWithPaypal {
  return {
    amount: data?.Amount ?? '',
    errorMessage: data?.ErrorType ? t('common:errors.generic') + ' (ErrorType ' + data?.ErrorType + ')' : ''
  };
}

export const normalizeLicenses = (licenses: any): ILicense[] => {
  return licenses.map((item: any) => {
    return {
      id: String(item?.id) ?? '',
      updatedLicenseId: item?.updatedLicenseId ?? 0,
      activationDate: item?.activationDate
        ? formatDate(new Date(item?.activationDate))
        : '',
      isActive: Boolean(item?.isActive),
      activationStatus: item?.activationStatus,
      expirationDate: item?.expirationDate
        ? formatDate(new Date(item?.expirationDate))
        : '',
      type:
        item?.type === 'SUPREMO'
          ? PRODUCT_TYPE.SUPREMO
          : item?.type === 'URANIUM'
            ? PRODUCT_TYPE.URANIUM
            : PRODUCT_TYPE.CONSOLE,
      title: item?.title ?? '',
      userNotes: item?.userNotes ?? '',
      renewableStatus: item?.renewableStatus ?? '',
      machineCode: item?.machineCode ?? '',
      activationCode: item?.activationCode ?? '',
      isUpgradable: Boolean(item?.upgradable),
      paymentId: item?.paymentId ?? 0,
      isRenewable: Boolean(item?.renevable),
      isProfessional: Boolean(item?.profileId),
    };
  });
};

const normalizeLicensesFromPurchase = (license: ILicenseThumbnail) => {
  return {
    iconUrl: license?.iconUrl ?? '',
    description: license?.description ?? '',
  };
};

const normalizeDocumentsFromPurchase = (document: IPurchaseDocument) => {
  return {
    type: document?.type ?? '',
  };
};
export const normalizePurchases = (purchases: any): IPurchase[] => {
  return purchases.map((item: any) => {
    return {
      id: String(item?.id) ?? '',
      activationDate: item?.activationDate
        ? formatDate(new Date(item?.activationDate))
        : '',
      price: item?.price ?? '',
      priceWithVAT: item?.priceWithVAT ?? '',
      paymentType: item?.paymentType ?? '',
      status: item?.status ?? '',
      licenses: item?.licenses.map(normalizeLicensesFromPurchase) ?? [],
      documents: item?.documents.map(normalizeDocumentsFromPurchase) ?? [],
    };
  });
};

export const normalizeUserAccountData = (data: any): UserDataResponse => {
  return {
    phase1: {
      username: data?.registerPhase1?.Username ?? '',
      email: data?.registerPhase1?.Email ?? '',
      password: Base64.decode(data?.registerPhase1?.Password) ?? '',
    },
    phase2: {
      userType: data?.registerPhase2?.TipoUtente ?? '',
      firstnameAndLastname: data?.registerPhase2?.NomeCognome ?? '',
      country: data?.registerPhase2?.Nazione ?? '',
      vatNumber: data?.registerPhase2?.Vat ?? '',
      paCode: data?.registerPhase2?.CodicePA ?? '',
      provinceState: data?.registerPhase2?.StatoEstero ?? '',
      province: data?.registerPhase2?.Provincia ?? '',
      fiscalCode: data?.registerPhase2?.CodiceFiscale ?? '',
      codeOrPec: data?.registerPhase2?.B2BPEC ? data?.registerPhase2?.B2BPEC.replace('XXXXXXX', '') : '',
    },

    phase3: {
      city: data?.registerPhase3?.Citta ?? '',
      streetAndNumber: data?.registerPhase3?.Civico,
      zipCode: data?.registerPhase3?.Cap ?? '',
      tel: data?.registerPhase3?.Telefono ?? '',
      adminEmail: data?.registerPhase3?.AdminEmail ?? '',
      newsletter: data?.registerPhase3?.Newsletter ?? '',
      codiceConcessionario: data?.registerPhase3?.CodiceConcessionario ?? '',
    },
  };
};

export const normalizeProductToGetEval = (
  product: ProductVersion,
  connections: number,
  teamId: number
) => {
  return {
    id: product?.id,
    price: product?.price,
    quantity: product?.quantity,
    toUpgradeID: product?.upgradeId,
    renewEnabled: product?.autoRenewable,
    name: product?.name,
    MinAmount: product?.minAmount,
    MaxAmount: product?.maxAmount,
    itaDescription: product?.description,
    Description: product?.shortDescription,
    sortIndex: product?.sortIndex,
    img: product?.image,
    cumulable: product?.isCumulable,
    renewable: product?.isRenewable,
    site: product?.site,
    months: product?.durationMonths,
    promotionalCode: product?.promotionalCode,
    PromotionalCodeDiscountAmount: product?.promotionalDiscountAmount,
    profile: teamId,
    usilioIntegration: product?.usilioIntegration,
    childs: [
      {
        id: product?.subProducts[0]?.id ?? '',
        price: product?.subProducts[0]?.price ?? 0,
        quantity: connections,
        toUpgradeID: product?.subProducts[0]?.upgradeId ?? '',
        renewEnabled: product?.subProducts[0]?.autoRenewable,
        name: product?.subProducts[0]?.name ?? '',
        MinAmount: product?.subProducts[0]?.minAmount,
        MaxAmount: product?.subProducts[0]?.maxAmount,
        itaDescription: product?.subProducts[0]?.description ?? '',
        Description: product?.subProducts[0]?.shortDescription ?? '',
        sortIndex: product?.subProducts[0]?.sortIndex ?? 0,
        img: product?.subProducts[0]?.image ?? '',
        cumulable: product?.subProducts[0]?.isCumulable,
        renewable: product?.subProducts[0]?.isRenewable,
        site: product?.subProducts[0]?.site ?? '',
        months: product?.subProducts[0]?.durationMonths,
        promotionalCode: product?.subProducts[0]?.promotionalCode ?? '-',
        PromotionalCodeDiscountAmount:
          product?.subProducts[0]?.promotionalDiscountAmount,
        profile: 0,
        usilioIntegration: product?.subProducts[0]?.usilioIntegration,
        childs: product?.subProducts[0]?.subProducts ?? [],
        newExpire: product?.subProducts[0]?.newExpire,
      },
    ],

    newExpire: product?.newExpire,
  };
};

export function normalizeUpgradedProductForCart(
  productVersion: any,
  profiles: any,
  localId?: string
): ProductVersion {
  const subProducts: Partial<ProductVersion[]> =
    productVersion?.childs
      ?.map(normalizeUpgradedProductForCartRecursion)
      .map((subProd: ProductVersion) => omit(subProd, ['subProducts'])) || [];

  const firstChild = first(subProducts);
  return {
    id: productVersion?.id,
    price: productVersion?.price ?? 0,
    quantity: productVersion?.quantity ?? 0,
    upgradeId: productVersion?.toUpgradeID ?? null,
    autoRenewable: Boolean(productVersion.renewEnabled),
    name: productVersion?.name ?? '',
    minAmount: firstChild?.minAmount ?? productVersion?.MinAmount ?? 0,
    maxAmount: firstChild?.maxAmount ?? productVersion?.MaxAmount ?? 0,
    description: productVersion?.itaDescription ?? '',
    shortDescription: productVersion?.Description ?? '',
    image: productVersion?.image ?? '',
    sortIndex: productVersion?.sortIndex ?? 0,
    isCumulable: Boolean(productVersion.cumulable),
    subscriptionDuration:
      getSubscriptionDurationType(productVersion?.months) ?? 0,
    isRenewable: Boolean(productVersion.renewable),
    site: productVersion?.site ?? '',
    promotionalCode: productVersion?.promotionalCode ?? '',
    promotionalDiscountAmount:
      productVersion?.PromotionalCodeDiscountAmount ?? 0,
    usilioIntegration: Boolean(productVersion.usilioIntegration),
    shouldShowMultiConnection: Boolean(productVersion?.childs?.length ?? 0),
    durationMonths: productVersion.months ?? undefined,
    profile: productVersion?.profile,
    newExpire: productVersion?.newExpire,
    promoDesc: productVersion?.promoDesc,
    profiles,
    localId,
    subProducts,
  };
}

export function normalizeUpgradedProductForCartRecursion(
  productVersion: any
): ProductVersion {
  const subProducts: Partial<ProductVersion[]> =
    productVersion?.childs
      ?.map(normalizeUpgradedProductForCartRecursion)
      .map((subProd: ProductVersion) => omit(subProd, ['subProducts'])) || [];

  const firstChild = first(subProducts);
  return {
    id: productVersion?.id,
    price: productVersion?.price ?? 0,
    quantity: productVersion?.quantity ?? 0,
    upgradeId: productVersion?.toUpgradeID ?? null,
    autoRenewable: Boolean(productVersion.renewEnabled),
    name: productVersion?.name ?? '',
    minAmount: firstChild?.minAmount ?? productVersion?.MinAmount ?? 0,
    maxAmount: firstChild?.maxAmount ?? productVersion?.MaxAmount ?? 0,
    description: productVersion?.itaDescription ?? '',
    shortDescription: productVersion?.Description ?? '',
    image: productVersion?.image ?? '',
    sortIndex: productVersion?.sortIndex ?? 0,
    isCumulable: Boolean(productVersion.cumulable),
    subscriptionDuration:
      getSubscriptionDurationType(productVersion?.months) ?? 0,
    isRenewable: Boolean(productVersion.renewable),
    site: productVersion?.site ?? '',
    promotionalCode: productVersion?.promotionalCode ?? '',
    promotionalDiscountAmount:
      productVersion?.PromotionalCodeDiscountAmount ?? 0,
    usilioIntegration: Boolean(productVersion.usilioIntegration),
    shouldShowMultiConnection: Boolean(productVersion?.childs?.length ?? 0),
    durationMonths: productVersion.months ?? undefined,
    profile: productVersion?.profile,
    newExpire: productVersion?.newExpire,
    promoDesc: productVersion?.promoDesc,
    subProducts,
  };
}

export function normalizeUpdateUserData(data: any) {
  const phase1 = pickBy(
    {
      Email: data?.email,
      Password: Base64.encode(data?.password),
    },
    identity
  );

  const phase2 = pickBy(
    {
      NomeCognome: data?.firstnameAndLastname ?? '',
      Nazione: data?.country ?? '',
      Provincia: data?.province ?? '',
      StatoEstero: data?.provinceState ?? '',
      B2BPEC: data?.codeOrPec ?? '',
    },
    identity
  );

  const phase3 = pickBy(
    {
      Cap: (data?.zipCode || data?.zipCodeITA) ?? '',
      Citta: data?.city ?? '',
      Civico: data?.streetAndNumber ?? '',
      Newsletter: data?.newsletter ?? '',
      Telefono: data?.tel ?? '',
      CodiceConcessionario: '',
      AdminEmail: data?.adminEmail ?? '',
    },
    identity
  );
  const phase = {
    phase: !isEmpty(phase1)
      ? 'PHASE1'
      : !isEmpty(phase2)
        ? 'PHASE2'
        : !isEmpty(phase3)
          ? 'PHASE3'
          : '',
  };
  return omitBy({ ...phase, phase1, phase2, phase3 }, isEmpty);
}
